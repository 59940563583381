import { storeToRefs } from 'pinia'
import rules from '@/data/list-filter-rules'
import { useListFiltersStore } from '@/stores/listFilters'

export default {
  moduleLink: 'list',
  init(view) {
    view.params[this.name].form = this.form
  },
  handles(view) {
    return {
      updateView: () => {
        view.modules[this.moduleLink].uses.get(view)
      },

      setSelectFilterOptions: column => {
        if (column.type !== 'select') return

        view.isLoading.filters = true

        view.api({
          endPoint: 'form-field-dataset',
          method: 'post',
          body: {
            form: this.form,
            field_id: column.id
          },
          callback: res => {
            view.model[this.name].optionsFields[column.field] = res
            view.isLoading.filters = false
          }
        })
      }
    }
  },
  elements(view) {
    const listFiltersStore = useListFiltersStore(this.form)
    const { columns } = storeToRefs(listFiltersStore)

    const getField = row => columns.value.find(c => c.field === row.field)

    const getFieldRules = row =>
      getField(row) ? rules.fieldTypes[getField(row).type] : null

    if (!view.params[this.moduleLink]) return

    return [
      {
        element: () => 'multiselect',
        placeholder: () => view.$t('view-manager-select-field'),
        label: 'label',
        trackBy: 'label',
        value: row => getField(row),
        options: () =>
          columns.value.filter(
            f => f.field !== 'actions' && f.type !== 'hidden'
          ),
        onInput: (param, row) => {
          row.field = param.field
          row.field_type = param.field_type
          row.operator = null
          row.value = null

          this.handles(view).setSelectFilterOptions(param)
        }
      },
      {
        element: () => 'multiselect',
        placeholder: () => view.$t('view-manager-select-operator'),
        label: 'label',
        trackBy: 'label',
        disabled: () => false,
        value: row =>
          rules.operators
            .filter(o => o.code === row.operator)
            .map(o => ({
              ...o,
              label: view.$t(o.translationKey)
            })),
        options: row => {
          if (!row.field) return []

          return rules.operators
            .filter(operator =>
              rules.fieldTypes[getField(row).type].possibleOperators.includes(
                operator.code
              )
            )
            .map(operator => ({
              ...operator,
              label: view.$t(operator.translationKey)
            }))
        },
        onInput: (param, row) => {
          row.operator = param.code
          row.value = null
        }
      },
      {
        element: row =>
          getFieldRules(row) && !['LIKE', 'NOT LIKE'].includes(row.operator)
            ? getFieldRules(row).tag
            : 'b-input',
        if: row =>
          row.operator && !['IS_EMPTY', 'IS_NOT_EMPTY'].includes(row.operator),
        placeholder: row =>
          !['LIKE', 'NOT LIKE'].includes(row.operator)
            ? view.$t(getFieldRules(row).placeholder)
            : null,
        trackBy: 'id',
        value: row => {
          switch (row.field_type) {
            case 'select':
              return row.value
            case 'date':
            case 'datetime':
              return row.value ? new Date(row.value) : null
            default:
              return row.value
          }
        },
        onInput: (param, row) => {
          row.value = param
        },
        isMultiple: row => ['IN', 'NOT_IN'].includes(row.operator),
        icon: row => (getFieldRules(row).icon ? getFieldRules(row).icon : null),
        options: row =>
          (view.model[this.name].optionsFields[row.field] || []).map(
            option => ({
              id: option[Object.keys(option)[0]],
              label: option[Object.keys(option)[1]]
            })
          ),
        appendToBody: row =>
          ['b-datepicker', 'b-datetimepicker'].includes(getFieldRules(row).tag),
        position: 'is-bottom-left'
      }
    ]
  }
}
