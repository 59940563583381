import { downloadFileNew } from '@/services/document'
import { i18n } from '@/plugins/i18n'

export const features = [
  {
    if: ({ handles, model, params, _this }) => true,
    disabled: () => false,
    feature: 'hellow-world',
    sequency: 1,
    component: 'b-button',
    content: {
      element: 'b-icon',
      title: i18n.t('action-button-edit'),
      icon: 'pencil'
    },
    class: 'is-primary',
    onClick: () => alert('Hello World!')
  },
  {
    if: () => true,
    disabled: () => false,
    feature: 'order-list-actions-view',
    sequency: 1,
    component: 'router-link',
    to: `/orders/view/:id`,
    content: {
      element: 'b-icon',
      title: i18n.t('action-button-show'),
      icon: 'eye'
    },
    class: 'button is-primary is-small is-outlined rounded-icons'
  },
  {
    if: () => true,
    disabled: () => false,
    feature: 'order-item-list-actions-view',
    sequency: 1,
    component: 'router-link',
    to: '/orders/view/:order_id',
    content: {
      element: 'b-icon',
      title: i18n.t('action-button-show'),
      icon: 'eye'
    },
    class: 'button is-primary is-small is-outlined rounded-icons'
  },
  {
    if: () => true,
    disabled: ({ row }) => !!row.deleted_at,
    feature: 'order-item-list-action-delete',
    sequency: 1,
    component: 'b-button',
    content: {
      element: 'b-icon',
      title: i18n.t('delete'),
      icon: 'delete'
    },
    class: 'is-danger is-small is-outlined rounded-icons',
    onClick: ({ _this, row }) =>
      _this.$buefy.dialog.confirm({
        type: 'is-danger',
        message: _this.$t('delete_question'),
        endPoint: 'order-item-delete',
        body: { id: row.id },
        onConfirm: () => {
          _this.$parent.api({
            method: 'post',
            endPoint: 'order-item-delete',
            body: { id: row.id },
            callback: async res => {
              location.reload()
            }
          })
        }
      })
  },
  {
    if: () => true,
    disabled: () => false,
    feature: 'order-list-actions-edit',
    sequency: 1,
    component: 'router-link',
    to: '/orders/edit/:id',
    content: {
      element: 'b-icon',
      title: i18n.t('action-button-edit'),
      icon: 'pencil'
    },
    class: 'button is-primary is-small is-outlined rounded-icons'
  },
  {
    if: () => true,
    disabled: () => false,
    feature: 'company-list-action-view',
    sequency: 1,
    component: 'router-link',
    to: `/companies/:id`,
    content: {
      element: 'b-icon',
      title: i18n.t('action-button-show'),
      icon: 'eye'
    },
    class: 'button is-primary is-small is-outlined rounded-icons'
  },
  {
    if: () => true,
    disabled: () => false,
    feature: 'company-list-action-edit',
    sequency: 1,
    component: 'router-link',
    to: `/companies/edit/:id`,
    content: {
      element: 'b-icon',
      title: i18n.t('action-button-edit'),
      icon: 'pencil'
    },
    class: 'button is-primary is-small is-outlined rounded-icons'
  },
  {
    if: () => true,
    disabled: () => false,
    feature: 'company-list-action-duplicate',
    sequency: 1,
    component: 'router-link',
    to: '/companies/duplicate/:id',
    content: {
      element: 'b-icon',
      title: i18n.t('duplicate-company'),
      icon: 'arrange-bring-forward'
    },
    class: 'button is-primary is-small is-outlined rounded-icons'
  },
  {
    if: () => true,
    disabled: () => false,
    feature: 'routing-list-action-view',
    sequency: 1,
    component: 'router-link',
    to: '/routing/matrix/show/:id',
    content: {
      element: 'b-icon',
      title: i18n.t('action-button-show'),
      icon: 'eye'
    },
    class: 'button is-primary is-small is-outlined rounded-icons'
  },
  {
    if: () => true,
    disabled: () => false,
    feature: 'routing-list-action-duplicate',
    sequency: 1,
    component: 'router-link',
    to: '/routing/matrix/duplicate/:id',
    content: {
      element: 'b-icon',
      title: i18n.t('duplicate-routing-matrix'),
      icon: 'arrange-bring-forward'
    },
    class: 'button is-primary is-small is-outlined rounded-icons'
  },
  {
    if: () => true,
    disabled: ({ row }) => !!row.deleted_at,
    feature: 'routing-list-action-delete',
    sequency: 1,
    component: 'b-button',
    content: {
      element: 'b-icon',
      title: i18n.t('delete'),
      icon: 'delete'
    },
    class: 'is-danger is-small is-outlined rounded-icons',
    onClick: ({ _this, row }) =>
      _this.$buefy.dialog.confirm({
        type: 'is-danger',
        message: _this.$t('delete_question'),
        endPoint: 'routing-matrix-delete',
        body: { id: row.id },
        onConfirm: () => {
          _this.$parent.api({
            method: 'post',
            endPoint: 'routing-matrix-delete',
            body: { id: row.id },
            callback: async res => {
              location.reload()
            }
          })
        }
      })
  },
  {
    if: () => true,
    disabled: () => false,
    feature: 'price-list-action-view',
    sequency: 1,
    component: 'router-link',
    to: '/price/matrix/show/:id',
    content: {
      element: 'b-icon',
      title: i18n.t('action-button-show'),
      icon: 'eye'
    },
    class: 'button is-primary is-small is-outlined rounded-icons'
  },
  {
    if: () => true,
    disabled: () => false,
    feature: 'price-list-action-duplicate',
    sequency: 1,
    component: 'router-link',
    to: '/price/matrix/duplicate/:id',
    content: {
      element: 'b-icon',
      title: i18n.t('duplicate-price-matrix'),
      icon: 'arrange-bring-forward'
    },
    class: 'button is-primary is-small is-outlined rounded-icons'
  },
  {
    if: () => true,
    disabled: ({ row }) => !!row.deleted_at,
    feature: 'price-list-action-delete',
    sequency: 1,
    component: 'b-button',
    content: {
      element: 'b-icon',
      title: i18n.t('delete'),
      icon: 'delete'
    },
    class: 'is-danger is-small is-outlined rounded-icons',
    onClick: ({ _this, row }) =>
      _this.$buefy.dialog.confirm({
        type: 'is-danger',
        message: _this.$t('delete_question'),
        endPoint: 'price-matrix-delete',
        body: { id: row.id },
        onConfirm: () => {
          _this.$parent.api({
            method: 'post',
            endPoint: 'price-matrix-delete',
            body: { id: row.id },
            callback: async res => {
              location.reload()
            }
          })
        }
      })
  },
  {
    if: () => true,
    disabled: () => false,
    feature: 'price-company-list-action-view',
    sequency: 1,
    component: 'router-link',
    to: '/price/matrix/company/show/:id',
    content: {
      element: 'b-icon',
      title: i18n.t('action-button-show'),
      icon: 'eye'
    },
    class: 'button is-primary is-small is-outlined rounded-icons'
  },
  {
    if: () => true,
    disabled: () => false,
    feature: 'price-company-list-action-duplicate',
    sequency: 1,
    component: 'router-link',
    to: '/price/matrix/company/duplicate/:id',
    content: {
      element: 'b-icon',
      title: i18n.t('duplicate-price-matrix'),
      icon: 'arrange-bring-forward'
    },
    class: 'button is-primary is-small is-outlined rounded-icons'
  },
  {
    if: () => true,
    disabled: ({ row }) => !!row.deleted_at,
    feature: 'price-company-list-action-delete',
    sequency: 1,
    component: 'b-button',
    content: {
      element: 'b-icon',
      title: i18n.t('delete'),
      icon: 'delete'
    },
    class: 'is-danger is-small is-outlined rounded-icons',
    onClick: ({ _this, row }) =>
      _this.$buefy.dialog.confirm({
        type: 'is-danger',
        message: _this.$t('delete_question'),
        endPoint: 'price-matrix-company-rule-delete',
        body: { id: row.id },
        onConfirm: () => {
          _this.$parent.api({
            method: 'post',
            endPoint: 'price-matrix-company-rule-delete',
            body: { id: row.id },
            callback: async res => {
              location.reload()
            }
          })
        }
      })
  },
  {
    if: () => true,
    disabled: () => false,
    feature: 'contract-list-action-view',
    sequency: 1,
    component: 'router-link',
    to: '/contract/matrix/show/:id',
    content: {
      element: 'b-icon',
      title: i18n.t('action-button-show'),
      icon: 'eye'
    },
    class: 'button is-primary is-small is-outlined rounded-icons'
  },
  {
    if: () => true,
    disabled: () => false,
    feature: 'contract-list-action-duplicate',
    sequency: 1,
    component: 'router-link',
    to: '/contract/matrix/duplicate/:id',
    content: {
      element: 'b-icon',
      title: i18n.t('duplicate'),
      icon: 'arrange-bring-forward'
    },
    class: 'button is-primary is-small is-outlined rounded-icons'
  },
  {
    if: () => true,
    disabled: ({ row }) => !!row.deleted_at,
    feature: 'contract-list-action-delete',
    sequency: 1,
    component: 'b-button',
    content: {
      element: 'b-icon',
      title: i18n.t('delete'),
      icon: 'delete'
    },
    class: 'is-danger is-small is-outlined rounded-icons',
    onClick: ({ _this, row }) =>
      _this.$buefy.dialog.confirm({
        type: 'is-danger',
        message: _this.$t('delete_question'),
        endPoint: 'contract-matrix-delete',
        body: { id: row.id },
        onConfirm: () => {
          _this.$parent.api({
            method: 'post',
            endPoint: 'contract-matrix-delete',
            body: { id: row.id },
            callback: async res => {
              location.reload()
            }
          })
        }
      })
  },
  {
    if: () => true,
    disabled: () => false,
    feature: 'sm-flow-list-action-view',
    sequency: 1,
    component: 'router-link',
    to: '/sm-flow/matrix/show/:id',
    content: {
      element: 'b-icon',
      title: i18n.t('action-button-show'),
      icon: 'eye'
    },
    class: 'button is-primary is-small is-outlined rounded-icons'
  },
  {
    if: () => true,
    disabled: () => false,
    feature: 'sm-flow-list-action-duplicate',
    sequency: 1,
    component: 'router-link',
    to: '/sm-flow/matrix/duplicate/:id',
    content: {
      element: 'b-icon',
      title: i18n.t('duplicate-sm-flow-matrix'),
      icon: 'arrange-bring-forward'
    },
    class: 'button is-primary is-small is-outlined rounded-icons'
  },
  {
    if: () => true,
    disabled: ({ row }) => !!row.deleted_at,
    feature: 'sm-flow-list-action-delete',
    sequency: 1,
    component: 'b-button',
    content: {
      element: 'b-icon',
      title: i18n.t('delete'),
      icon: 'delete'
    },
    class: 'is-danger is-small is-outlined rounded-icons',
    onClick: ({ _this, row }) =>
      _this.$buefy.dialog.confirm({
        type: 'is-danger',
        message: _this.$t('delete_question'),
        endPoint: 'sm-flow-matrix-delete',
        body: { id: row.id },
        onConfirm: () => {
          _this.$parent.api({
            method: 'post',
            endPoint: 'sm-flow-matrix-delete',
            body: { id: row.id },
            callback: async res => {
              location.reload()
            }
          })
        }
      })
  },
  {
    if: () => true,
    disabled: () => false,
    feature: 'notification-list-action-edit',
    sequency: 1,
    component: 'router-link',
    to: `/notifications/edit/:id`,
    content: {
      element: 'b-icon',
      title: i18n.t('action-button-edit'),
      icon: 'pencil'
    },
    class: 'button is-primary is-small is-outlined rounded-icons'
  },
  {
    if: () => true,
    disabled: ({ row }) =>
      !!row.deleted_at ||
      row.frontend_notification_type_name === 'Bell Notification',
    feature: 'notification-list-action-delete',
    sequency: 1,
    component: 'b-button',
    content: {
      element: 'b-icon',
      title: i18n.t('delete'),
      icon: 'delete'
    },
    class: 'is-danger is-small is-outlined rounded-icons',
    onClick: ({ _this, row }) =>
      _this.$buefy.dialog.confirm({
        type: 'is-danger',
        message: _this.$t('delete_question'),
        endPoint: '/notifications/delete',
        body: { id: row.id },
        onConfirm: () => {
          _this.$parent.api({
            method: 'post',
            endPoint: '/notifications/delete',
            body: { id: row.id },
            callback: async res => {
              location.reload()
            }
          })
        }
      })
  },
  {
    if: () => true,
    disabled: () => false,
    feature: 'canned-reports-list-action-generate',
    sequency: 1,
    component: 'b-button',
    content: {
      element: 'b-icon',
      title: i18n.t('canned-report-generate-button'),
      icon: 'file-download'
    },
    class: 'is-primary is-small rounded-icons',
    onClick: ({ _this, row }) =>
      row.form_definition
        ? _this.$parent.modal({
            component: 'DynamicForm',
            props: {
              res: null,
              model: _this.model.model.model,
              params: {
                title: row.title,
                forms: [row.form_definition.slug]
              },
              hasCard: 'true',
              handles: {
                api: _this.$parent.api,
                onCreate: _this =>
                  _this.$parent.$parent.api({
                    endPoint: 'authenticated-forms',
                    method: 'post',
                    body: {
                      form: row.form_definition.slug
                    },
                    callback: res => {
                      res = res.actions
                        ? res.actions[Object.keys(res.actions)[0]]
                            .route_response.content
                        : res
                      _this.formBuilder({
                        formDependency: row.form_definition.slug,
                        ...res,
                        fields: res.fields
                      })
                    }
                  })
              },
              elements: [
                {
                  component: 'b-button',
                  label: _this.$t('button-close'),
                  onClick: _this => _this.$emit('close')
                },
                {
                  component: 'b-button',
                  label: _this.$t('button-submit'),
                  class: 'is-primary',
                  loading: false,
                  onClick: _this =>
                    _this.formValidation({
                      success: result =>
                        _this.$parent.$parent.handles.list.setAction({
                          dialog: true,
                          download: true,
                          endpoint:
                            _this.$parent.$parent.modules.list.props.endPoints
                              .calculate,
                          finally: () => _this.$emit('close'),
                          params: {
                            ...row,
                            ...result.payload
                          }
                        })
                    })
                }
              ]
            }
          })
        : _this.handles.setAction({
            dialog: true,
            endpoint: _this._props.module.props.endPoints.download,
            params: row,
            download: true
          })
  },
  {
    if: () => true,
    disabled: () => false,
    feature: 'canned-report-download-history',
    sequency: 1,
    component: 'b-button',
    content: {
      element: 'b-icon',
      title: 'Download History',
      icon: 'history'
    },
    class: 'is-primary is-small is-outlined rounded-icons',
    onClick: ({ _this, row }) => {
      _this.handles.checkModal(
        {
          dialog: false,
          endpoint: 'canned-report-download-history-list',
          name: 'canned-report-download-history-list',
          params: {
            id: row.id || row.order_id,
            list_form: true,
            form: 'canned-report-download-history-list'
          }
        },
        row
      )
    }
  },
  {
    if: () => true,
    disabled: () => false,
    feature: 'users-list-action-view',
    sequency: 1,
    component: 'router-link',
    to: '/users/view/:id',
    content: {
      element: 'b-icon',
      title: i18n.t('action-button-show'),
      icon: 'eye'
    },
    class: 'button is-primary is-small is-outlined rounded-icons'
  },
  {
    if: () => true,
    disabled: () => false,
    feature: 'users-list-action-edit',
    sequency: 1,
    component: 'router-link',
    to: '/users/edit/:id',
    content: {
      element: 'b-icon',
      title: i18n.t('action-button-edit'),
      icon: 'pencil'
    },
    class: 'button is-primary is-small is-outlined rounded-icons'
  },
  {
    if: () => true,
    disabled: () => false,
    feature: 'invoices-list-action-download',
    sequency: 1,
    component: 'b-button',
    content: {
      element: 'b-icon',
      title: 'Invoice Download',
      icon: 'file-download'
    },
    class: 'is-primary is-small rounded-icons',
    onClick: ({ _this, row }) =>
      _this.handles.setAction({
        dialog: false,
        endpoint: `invoicing/document-builder/download/${row.document_builder_id}`,
        params: row,
        download: true,
        title: _this.$t('download')
      })
  },
  {
    if: () => true,
    disabled: () => false,
    feature: 'documents-list-action-download',
    sequency: 1,
    component: 'b-button',
    content: {
      element: 'b-icon',
      title: 'Download Document',
      icon: 'file-download'
    },
    class: 'is-primary is-small rounded-icons',
    onClick: ({ _this, row }) =>
      _this.handles.setAction({
        dialog: false,
        endpoint: `download-document`,
        params: row,
        download: true,
        title: _this.$t('download')
      })
  },
  {
    if: () => true,
    disabled: () => false,
    feature: 'invoices-list-action-send',
    sequency: 1,
    component: 'b-button',
    content: {
      element: 'b-icon',
      title: 'invoice-email-send',
      icon: 'email-outline'
    },
    class: 'is-primary is-small rounded-icons',
    onClick: ({ _this, row }) =>
      _this.handles.setAction({
        dialog: true,
        endpoint: `invoicing/document-builder/send-email/${row.document_builder_id}`,
        params: row,
        download: false,
        title: _this.$t('send-email'),
        message: _this.$t('send-email-question')
      })
  },
  {
    if: () => true,
    disabled: ({ row }) =>
      ![3, 6, 7, 10, 30, 40].includes(row.invoice_preview_status_id),
    feature: 'invoices-preview-action-export',
    sequency: 1,
    component: 'b-button',
    content: {
      element: 'b-icon',
      title: 'download-preview',
      icon: 'file-download'
    },
    class: 'is-primary is-small rounded-icons',
    onClick: ({ _this, row }) =>
      _this.handles.setAction({
        dialog: false,
        endpoint: 'invoicing/calculate-preview-export',
        params: row,
        download: true,
        title: _this.$t('invoice-download-preview-filename')
      })
  },
  {
    if: ({ row }) => row.preview_generator_class === null,
    disabled: ({ row }) =>
      ![3, 4, 6, 7, 10, 30, 40].includes(row.invoice_preview_status_id),
    feature: 'invoices-preview-action-download-orders',
    sequency: 1,
    component: 'b-button',
    content: {
      element: 'b-icon',
      title: 'order-items-not-invoiced-export',
      icon: 'currency-usd-off'
    },
    class: 'is-primary is-small rounded-icons',
    onClick: ({ _this, row }) =>
      _this.$parent.handles.list.setAction({
        dialog: false,
        endpoint: 'invoicing/preview-no-prices',
        params: row,
        download: true,
        title: _this.$t('invoice-download-no-price-filename')
      })
  },
  {
    if: () => true,
    disabled: ({ row }) => ![3].includes(row.invoice_preview_status_id),
    feature: 'invoices-preview-action-generate',
    sequency: 1,
    component: 'b-button',
    content: {
      element: 'b-icon',
      title: 'invoicing-generate_button',
      icon: 'currency-usd'
    },
    class: 'is-primary is-small rounded-icons',
    onClick: ({ _this, row }) =>
      _this.handles.setAction({
        dialog: true,
        endpoint: 'invoicing/generate',
        params: row,
        title: _this.$t('invoicing-generate_button'),
        message: _this.$t('invoicing-generate_confirm_message')
      })
  },
  {
    if: () => true,
    disabled: ({ row }) => ![3].includes(row.invoice_preview_status_id),
    feature: 'invoices-preview-action-prevent-generate',
    sequency: 1,
    component: 'b-button',
    content: {
      element: 'b-icon',
      title: 'invoicing-prevent_generate',
      icon: 'file-lock'
    },
    class: 'is-danger is-small rounded-icons',
    onClick: ({ _this, row }) =>
      _this.handles.setAction({
        dialog: true,
        endpoint: 'invoicing-prevent-generate',
        params: row,
        title: ' ',
        message: _this.$t('invoice-preview-prevent_confirm_message')
      })
  },
  {
    if: () => true,
    disabled: () => false,
    feature: 'periods-list-view',
    sequency: 1,
    component: 'router-link',
    to: `/periods/view/:id`,
    content: {
      element: 'b-icon',
      title: i18n.t('action-button-show'),
      icon: 'eye'
    },
    class: 'button is-primary is-small is-outlined rounded-icons'
  },
  {
    if: () => true,
    disabled: () => false,
    feature: 'periods-list-edit',
    sequency: 1,
    component: 'router-link',
    to: `/periods/edit/:id`,
    content: {
      element: 'b-icon',
      title: i18n.t('action-button-edit'),
      icon: 'pencil'
    },
    class: 'button is-primary is-small is-outlined rounded-icons'
  },
  {
    if: () => true,
    disabled: () => false,
    feature: 'periods-list-duplicate',
    sequency: 1,
    component: 'router-link',
    to: '/periods/duplicate/:id',
    content: {
      element: 'b-icon',
      title: i18n.t('duplicate-period'),
      icon: 'arrange-bring-forward'
    },
    class: 'button is-primary is-small is-outlined rounded-icons'
  },
  {
    if: () => true,
    disabled: () => false,
    feature: 'uses-complaints-list',
    sequency: 1,
    component: 'b-button',
    content: {
      element: 'b-icon',
      title: 'Order Item Complaints List',
      icon: 'comment-remove'
    },
    class: 'is-primary is-small rounded-icons',
    outlined: true,
    onClick: ({ _this, row }) => {
      _this.$parent.api({
        module: this,
        method: 'post',
        endPoint: 'list-order-items-complaints',
        body: {
          form: 'list-order-items-complaints',
          order_item_id: row.id || row.order_item_id,
          list_form: true
        },
        callback: async res => {
          const data =
            res.actions.get_all_order_item_complaints.route_response.content

          const columns = data.form.fields.map(f => ({
            name: f.field_name,
            field: f.field_name,
            label: f.field_label,
            type: f.field_type,
            id: f.id,
            render:
              f.field_name === 'actions'
                ? {
                    field: 'actions',
                    searchable: false,
                    class: 'list-actions'
                  }
                : null
          }))

          _this.$parent.modal({
            component: 'DynamicList',
            props: {
              res,
              params: {
                paginated: false,
                filterable: false,
                title: data.form.form_name,
                data: data.dataset,
                columns
              },
              model: {}
            }
          })
        }
      })
    }
  },
  {
    if: () => true,
    disabled: () => false,
    feature: 'order-item-details-list',
    sequency: 2,
    component: 'b-button',
    content: {
      element: 'b-icon',
      title: 'Order Item Details List',
      icon: 'playlist-plus'
    },
    class: 'is-primary is-small rounded-icons',
    outlined: true,
    onClick: ({ _this, row }) => {
      _this.$parent.api({
        module: this,
        method: 'post',
        endPoint: 'list-form-order-item-details',
        body: {
          form: 'list-form-order-item-details',
          order_item_id: row.id || row.order_item_id,
          list_form: true
        },
        callback: async res => {
          const data = res.actions.get_all_details.route_response.content

          const columns = data.form.fields.map(f => ({
            name: f.field_name,
            field: f.field_name,
            label: f.field_label,
            type: f.field_type,
            id: f.id,
            render:
              f.field_name === 'actions'
                ? {
                    field: 'actions',
                    searchable: false,
                    class: 'list-actions'
                  }
                : null
          }))

          _this.$parent.modal({
            component: 'DynamicList',
            props: {
              res,
              params: {
                paginated: false,
                filterable: false,
                title: data.form.form_name,
                data: data.dataset,
                columns
              },
              model: {}
            }
          })
        }
      })
    }
  },
  {
    if: () => true,
    disabled: () => false,
    feature: 'order-invoices-list',
    sequency: 1,
    component: 'b-button',
    content: { element: 'b-icon', title: 'Download', icon: 'download' },
    class: 'is-primary is-small rounded-icons',
    outlined: true,
    onClick: ({ _this, row }) =>
      downloadFileNew(
        `invoicing/document-builder/download/${row.document_builder_id}`
      ).then(response => {
        _this.isLoading = false

        if (response.status === 'error')
          return _this.$buefy.toast.open({
            message: _this.$t('invoicing-download_error'),
            position: 'is-top',
            type: 'is-danger',
            duration: 3000
          })

        const url = window.URL.createObjectURL(response)
        const a = document.createElement('a')

        a.href = url
        a.download = `${row.document_builder_id}.pdf`
        a.click()

        window.URL.revokeObjectURL(url)
      })
  },
  {
    if: () => true,
    disabled: () => false,
    feature: 'contract-articles-list-edit',
    sequency: 1,
    component: 'router-link',
    to: `/contract-articles/edit/:id`,
    content: {
      element: 'b-icon',
      title: i18n.t('action-button-edit'),
      icon: 'pencil'
    },
    class: 'button is-primary is-small is-outlined rounded-icons'
  },
  {
    if: () => true,
    disabled: ({ row }) => !!row.deleted_at,
    feature: 'contract-articles-list-delete',
    sequency: 1,
    component: 'b-button',
    content: {
      element: 'b-icon',
      title: i18n.t('delete'),
      icon: 'delete'
    },
    class: 'is-danger is-small is-outlined rounded-icons',
    onClick: ({ _this, row }) =>
      _this.$buefy.dialog.confirm({
        type: 'is-danger',
        message: _this.$t('contract_article_delete_question'),
        endPoint: 'app-contract-article-delete', // Confirmar o endpoint
        body: { id: row.id },
        onConfirm: () => {
          _this.$parent.api({
            method: 'post',
            endPoint: 'app-contract-article-delete', // Confirmar o endpoint
            body: { id: row.id },
            callback: async res => {
              location.reload()
            }
          })
        }
      })
  },
  {
    if: () => true,
    disabled: () => false,
    feature: 'manual-invoice-view',
    sequency: 1,
    component: 'router-link',
    to: '/manual-invoices/show/:id',
    content: {
      element: 'b-icon',
      title: i18n.t('action-button-show'),
      icon: 'eye'
    },
    class: 'button is-primary is-small is-outlined rounded-icons'
  },
  {
    if: ({ row }) => true,
    disabled: ({ row }) => row.bundle_id === '0',
    feature: 'order-item-consignment-notes',
    sequency: 1,
    component: 'b-button',
    to: `/orders-items/log?bundle_id=:bundle_id`,
    content: {
      element: 'b-icon',
      title: 'Show related items from consignment note',
      icon: 'text-box-search-outline'
    },
    class: 'button is-primary is-small is-outlined rounded-icons',
    onClick: ({ _this, row }) => {
      _this.$router.push(
        `${_this.$router.currentRoute.path}?bundle_id=${row.bundle_id}`
      )
    }
  },
  {
    if: () => true,
    disabled: () => false,
    feature: 'chunk-model-uploads-show',
    sequency: 1,
    component: 'router-link',
    to: '/uploads-details/:id',
    content: {
      element: 'b-icon',
      title: i18n.t('action-button-show'),
      icon: 'eye'
    },
    class: 'button is-primary is-small is-outlined rounded-icons',
    render: ({ _this, row }) => {
      let disabled = true
      if ([1].includes(row.status_id) === false) {
        disabled = false

        return {
          component: 'router-link',
          to: '/uploads-details/:id',
          content: {
            element: 'b-icon',
            title: i18n.t('action-button-show'),
            icon: 'eye'
          },
          class: 'button is-primary is-small is-outlined rounded-icons',
          outlined: true,
          position: 'is-top-left',
          disabled
        }
      }

      return {
        component: 'b-button',
        content: {
          element: 'b-icon',
          title: i18n.t('action-button-show'),
          class: 'is-small',
          icon: 'eye'
        },
        class: 'is-primary is-small rounded-icons',
        outlined: true,
        position: 'is-top-left',
        disabled
      }
    }
  },
  {
    if: ({ row, params }) =>
      [30896].includes(row.period_business_type_id) &&
      !params.isHistoryModal &&
      row.is_small_producer_period === '0',
    disabled: () => false,
    feature: 'declaration-view-action',
    sequency: 1,
    component: 'router-link',
    to: '/declaration/:period_id/:company_id/view',
    content: {
      element: 'b-icon',
      title: i18n.t('action-button-show'),
      icon: 'eye'
    },
    class: 'button is-primary is-small is-outlined rounded-icons'
  },
  {
    if: ({ row, params }) =>
      [30898].includes(row.period_business_type_id) && !params.isHistoryModal,
    disabled: ({ row }) => false,
    feature: 'declaration-view-action',
    sequency: 1,
    component: 'router-link',
    to: '/declaration-eee-view/:id',
    content: {
      element: 'b-icon',
      title: i18n.t('action-button-show'),
      icon: 'eye'
    },
    class: 'button is-primary is-small is-outlined rounded-icons'
  },
  {
    if: () => true,
    disabled: () => false,
    feature: 'user-storage-download',
    sequency: 1,
    component: 'b-button',
    content: { element: 'b-icon', title: 'Download', icon: 'download' },
    class: 'is-primary is-small rounded-icons',
    outlined: true,
    onClick: ({ _this, row }) =>
      downloadFileNew(`user-storage-files/download`, row).then(response => {
        _this.isLoading = false

        if (response.status === 'error')
          return _this.$buefy.toast.open({
            message: _this.$t('invoicing-download_error'),
            position: 'is-top',
            type: 'is-danger',
            duration: 3000
          })

        const binStr = atob(response.file_content)
        const len = binStr.length
        const arr = new Uint8Array(len)
        for (let i = 0; i < len; i++) {
          arr[i] = binStr.charCodeAt(i)
        }

        const blobAux = new Blob([arr], {
          type: response.content_type
        })
        const url = window.URL.createObjectURL(blobAux)
        const a = document.createElement('a')

        a.href = url
        a.download = `${response.file_name}`
        a.click()

        window.URL.revokeObjectURL(url)
      })
  },
  {
    if: ({ row, params }) =>
      [30896].includes(row.period_business_type_id) &&
      params.isHistoryModal &&
      row.is_small_producer_period === '0',
    disabled: () => false,
    feature: 'declaration-view-action',
    sequency: 1,
    component: 'router-link',
    to: '/declaration/:id/view',
    content: {
      element: 'b-icon',
      title: i18n.t('action-button-show'),
      icon: 'eye'
    },
    class: 'button is-primary is-small is-outlined rounded-icons',
    onClick: ({ _this, row }) => {
      _this.$emit('close')
    }
  },
  {
    if: ({ row, params }) =>
      [37740].includes(row.business_type_id) && !params.isHistoryModal,
    disabled: () => false,
    feature: 'declaration-view-action',
    sequency: 1,
    component: 'router-link',
    to: '/declaration/:id/view',
    content: {
      element: 'b-icon',
      title: i18n.t('action-button-show'),
      icon: 'eye'
    },
    class: 'button is-primary is-small is-outlined rounded-icons',
    onClick: ({ _this, row }) => {
      _this.$emit('close')
    }
  },
  {
    if: ({ row, params }) =>
      [37740].includes(row.period_business_type_id) && params.isHistoryModal,
    disabled: () => false,
    feature: 'declaration-view-action',
    sequency: 1,
    component: 'router-link',
    to: '/declaration-history/:id/view',
    content: {
      element: 'b-icon',
      title: i18n.t('action-button-show'),
      icon: 'eye'
    },
    class: 'button is-primary is-small is-outlined rounded-icons',
    onClick: ({ _this, row }) => {
      _this.$emit('close')
    }
  },
  {
    if: ({ row, params }) =>
      [30898].includes(row.period_business_type_id) && params.isHistoryModal,
    disabled: ({ row }) => false,
    feature: 'declaration-view-action',
    sequency: 1,
    component: 'router-link',
    to: '/declaration-eee-view-history/:id',
    content: {
      element: 'b-icon',
      title: i18n.t('action-button-show'),
      icon: 'eye'
    },
    class: 'button is-primary is-small is-outlined rounded-icons',
    onClick: ({ _this }) => {
      _this.$emit('close')
    }
  },
  {
    if: ({ row, params }) =>
      [30897].includes(row.period_business_type_id) && params.isHistoryModal,
    disabled: ({ row }) => false,
    feature: 'declaration-view-action',
    sequency: 1,
    component: 'router-link',
    to: '/declaration-bat-view-history/:id',
    content: {
      element: 'b-icon',
      title: i18n.t('action-button-show'),
      icon: 'eye'
    },
    class: 'button is-primary is-small is-outlined rounded-icons',
    onClick: ({ _this }) => {
      _this.$emit('close')
    }
  },
  {
    if: ({ row, params }) =>
      [30897].includes(row.period_business_type_id) && !params.isHistoryModal,
    disabled: ({ row }) => false,
    feature: 'declaration-view-action',
    sequency: 1,
    component: 'router-link',
    to: '/declaration-bat-view/:id',
    content: {
      element: 'b-icon',
      title: i18n.t('action-button-show'),
      icon: 'eye'
    },
    class: 'button is-primary is-small is-outlined rounded-icons',
    onClick: ({ _this }) => {
      _this.$emit('close')
    }
  },
  {
    if: ({ row, params }) =>
      [30896].includes(row.period_business_type_id) &&
      !params.isHistoryModal &&
      row.is_small_producer_period === '1',
    disabled: () => false,
    feature: 'declaration-view-action',
    sequency: 1,
    component: 'router-link',
    to: '/declaration_small/:period_id/:company_id/view',
    content: {
      element: 'b-icon',
      title: i18n.t('action-button-show'),
      icon: 'eye'
    },
    class: 'button is-primary is-small is-outlined rounded-icons'
  },
  {
    if: ({ row, params }) =>
      [30896].includes(row.period_business_type_id) &&
      params.isHistoryModal &&
      row.is_small_producer_period === '1',
    disabled: () => false,
    feature: 'declaration-view-action',
    sequency: 1,
    component: 'router-link',
    to: '/declaration_small/:id/view',
    content: {
      element: 'b-icon',
      title: i18n.t('action-button-show'),
      icon: 'eye'
    },
    class: 'button is-primary is-small is-outlined rounded-icons',
    onClick: ({ _this, row }) => {
      _this.$emit('close')
    }
  },
  {
    if: ({ row }) => row.state === 'documented',
    disabled: ({ row }) => !(row.state === 'documented'),
    feature: 'order-item-list-validate',
    sequency: 1,
    component: 'router-link',
    to: '/order-item/validation/:id/validate',
    content: {
      element: 'b-icon',
      title: i18n.t('action-button-validate'),
      icon: 'check'
    },
    class: 'button is-primary is-small is-outlined rounded-icons'
  },
  {
    if: ({ row }) => row.attachments !== '',
    disabled: () => false,
    feature: 'notification-data-builder-list-action-download',
    sequency: 1,
    component: 'b-button',
    content: {
      element: 'b-icon',
      title: i18n.t('action-button-download'),
      icon: 'download'
    },
    class: 'is-primary is-small rounded-icons',
    outlined: true,
    onClick: ({ _this, row }) =>
      downloadFileNew(`notification-databuilder-log/download/`, row).then(
        response => {
          _this.isLoading = false
          if (response.status === 'error')
            return _this.$buefy.toast.open({
              message: _this.$t('invoicing-download_error'),
              position: 'is-top',
              type: 'is-danger',
              duration: 3000
            })

          const binStr = atob(response.file_content)
          const len = binStr.length
          const arr = new Uint8Array(len)
          for (let i = 0; i < len; i++) {
            arr[i] = binStr.charCodeAt(i)
          }

          const blobAux = new Blob([arr], {
            type: response.content_type
          })
          const url = window.URL.createObjectURL(blobAux)
          const a = document.createElement('a')

          a.href = url
          a.download = `${response.file_name}`
          a.click()

          window.URL.revokeObjectURL(url)
        }
      )
  }
]
